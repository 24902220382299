import { ApiService, ConfigApi, SettingsService, StorageService } from 'services';
import {
    IConfigApi,
    IApiService,
    ISettingsService,
    IStorageService,
    INotificationOrderService,
    IActionApi,
    ICommitteeApi,
    ISpeakersApi,
    ISponsorsApi,
} from 'types';
import { NotificationOrderService } from './notification-order.service';

import { SponsorsApi, SpeakersApi, CommitteeApi, ActionsApi } from './api';

export const getApiService = (tokenStorageService: IStorageService): IApiService => new ApiService(tokenStorageService);
export const getConfigApiService = (apiService: IApiService): IConfigApi => new ConfigApi(apiService);
export const getActionsApiService = (apiService: IApiService): IActionApi => new ActionsApi(apiService);
export const getCommitteeApiService = (apiService: IApiService): ICommitteeApi => new CommitteeApi(apiService);
export const getSpeakersApiService = (apiService: IApiService): ISpeakersApi => new SpeakersApi(apiService);
export const getSettingsService = (): ISettingsService => new SettingsService();
export const getStorageService = (): IStorageService => new StorageService();
export const getNotificationOrderService = (): INotificationOrderService =>
    new NotificationOrderService(getApiService(getStorageService()));
export const getSponsorsApiService = (apiService: IApiService): ISponsorsApi => new SponsorsApi(apiService);
